if (document.querySelectorAll('[data-toggle="select-option"]').length > 0) {

    document.addEventListener('DOMContentLoaded', function () {

        const selectOption = document.querySelector('[data-toggle="select-option"]');

        selectOption.addEventListener('change', function() {
            const selectedOption = this.value;
            const rows = document.querySelectorAll('#dataTable tbody tr');

            rows.forEach(row => {
                if (selectedOption === 'reportAll' || row.classList.contains(selectedOption)) {
                    row.style.display = 'table-row';
                } else {
                    row.style.display = 'none';
                }
            });
        });

        // Initial update of table based on default selected option
        selectOption.dispatchEvent(new Event('change'));
    })
}