if (document.querySelectorAll('[data-toggle="dynamic-menu"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        $('.dropdown').on('shown.bs.dropdown', function () {

            const containers = document.querySelectorAll('[data-toggle="dynamic-menu"]');

            // Iterate through each element and create dynamic menu
            containers.forEach(function (container) {
                createDynamicMenu(container);
            });

            function createDynamicMenu(container) {
                const numColumns = parseInt(container.dataset.columns) || 1;
                const itemsPerColumn = parseInt(container.dataset.itemsPerColumn) || 1;

                 // Get the menu items
                const parent = container;
                const items = Array.from(parent.getElementsByClassName('menu-item'));

                // Calculate the total number of items
                const totalItems = items.length;

                // Clear existing content in the menu container
                parent.innerHTML = '';

                // Create columns and append items
                for (let colIndex = 0; colIndex < numColumns; colIndex++) {
                    const columnDiv = document.createElement('div');
                    columnDiv.classList.add('columns');

                    // Append items to the column
                    for (let rowIndex = 0; rowIndex < itemsPerColumn; rowIndex++) {
                        const menuItemIndex = colIndex * itemsPerColumn + rowIndex;
                        const menuItem = items[menuItemIndex];

                        if (menuItem) {
                            columnDiv.appendChild(menuItem.cloneNode(true));
                        }

                        // Stop if we reach the end of items
                        if (menuItemIndex + 1 === totalItems) {
                            break;
                        }
                    }

                    // Append the column to the menu container
                    parent.appendChild(columnDiv);
                }
            }
        })
    })
}