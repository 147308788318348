if (document.querySelectorAll('[data-component="dropdown"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        dropdownHovering();

        function dropdownHovering() {
            console.log('test')
            let parent = $('.dropdown');
            let dropdownMenu = $('.dropdown-menu');

            parent.on('hover', function () {
                $(this).find(dropdownMenu).stop(true, true).delay(200).fadeIn(500);
            }, function () {
                $(this).find(dropdownMenu).stop(true, true).delay(200).fadeOut(500);
            });
        }

        $('[data-bs-toggle="tab"]').on('mouseover', function (e) {
            e.preventDefault();
            e.stopPropagation();

            $(e.target).tab('show');
        });

        $('.dropdown-menu__subs-toggle a:not([data-bs-toggle="tab"])').on('mouseover', function (e) {
            e.preventDefault();
            e.stopPropagation();

            $('.tab-content .tab-pane').removeClass('active show');
            $('[data-bs-toggle="tab"]').removeClass('active');
        });

        $('.dropdown').on('hide.bs.dropdown', function (e) {
            $('.tab-content .tab-pane').removeClass('active show');
            $('[data-bs-toggle="tab"]').removeClass('active');
        })
    })
}